// // import Promise from 'promise-polyfill';

// // To add to window
// if (!window.Promise) {
//   window.Promise = Promise;
// }
const axios = require('axios');
// import scrollama from 'scrollama';

window.PATH_APP = '';

let ancho =  $(window).width();
let alto =  $(window).height();
let anchoSel = 85 * ancho / 100;
let altoSel = 75 * alto / 100;
let anchoSelMob = 92 * ancho / 100;
let altoSelMob = 80 * alto / 100;
let urlData = '../data/infos.json';
let urlDataIllus = '../data/illus.json';
let infosLand = document.getElementById("infosLand");
let illusLand = document.getElementById("illusLand");
let bigSel = document.getElementById("bigSel");
let bigBig = document.getElementById("bigBig");
let verBig = document.getElementById("verBig");
let verInfo = document.getElementById("verInfo");
let verIllus = document.getElementById("verIllus");
let infosFiltered = [];
let infosTotal = [];
let illusTotal = [];
let infosTotalFiltrada = [];
let illusFiltered = [];
let filtra = false;

let app = {
    init: function () {
        let requestDatos = axios.get(urlData);
        let requestDatosIllus = axios.get(urlDataIllus);

        axios.all([requestDatos, requestDatosIllus])
            .then(
                axios.spread((...responses) => {
                    let datos = responses[0].data;
                    let datosIllus = responses[1].data;
                    app.getData(datos, datosIllus);
                    app.armado(datos, "#infosLand");
                    app.armado(datosIllus, "#illusLand");
                    app.clickBoton();
                    app.clickHambur();
                    app.clickSelect(datos);
                    app.classLetters(hiTit);
                    app.classLetters(infosTit);
                    app.classLetters(illusTit);
                    app.classLetters(nombre);
                    app.classLetters(nombreMob);
                    app.classLetters(infosinBarreras);
                    app.classLetters(infothames);
                    app.classLetters(infoetiquetado);
                    app.classLetters(infolistasPaso);
                    app.classLetters(infocopaAmerica21);
                    app.classLetters(infovacunas);
                    app.classLetters(infomonitor);
                    app.classLetters(infoadopciones);
                    app.classLetters(infomarsPerseveranceB);
                    app.classLetters(infovirusColectivo);
                    app.classLetters(infovirusFiestas);
                    app.classLetters(infomaradonaFrases);
                    app.classLetters(infomessi700);
                    app.classLetters(infotablaCoronavirus);
                    app.classLetters(infofrasesSesiones1);
                    app.classLetters(infoespecial2020);
                    app.classLetters(infocompras100pe);
                    app.classLetters(infofixtureAmer20);
                    app.classLetters(infoescuelas2019);
                    app.classLetters(infodebatePresi2019);
                    app.classLetters(infopaso2019);
                    app.classLetters(infoluna50B);
                    app.classLetters(infocuras);
                    app.classLetters(infosubAra);
                    app.classLetters(infotronos2019);
                    app.classLetters(infojohnoliver);
                    app.classLetters(infog20favor);
                    app.classLetters(infoarmaBoca);
                    app.classLetters(infofinalLibertadores);
                    app.classLetters(infojjooJuventud);
                    app.classLetters(infomejorGol);
                    app.classLetters(infopuntajeLN);
                    app.classLetters(infoArgCroacia);
                    app.classLetters(infoestadios);
                    app.classLetters(infoelegiArg);
                    app.classLetters(infolugares);
                    app.classLetters(infodesaparecidos);
                    app.classLetters(infosubAraResumen);
                    app.classLetters(infogameTronos);
                    app.classLetters(infocalcEliminatorias);
                    app.classLetters(inforugbyB);
                    app.classLetters(infomexico86);
                    app.classLetters(infostarwars);
                    app.armaLineas();
                    app.tocaBody("#bigSel", "active");
                    app.tocaBody("#bigBig", "active");
                    app.tocaBody("#bigMenu", "active");
                    app.tocaBody("#opts", "open");
                })
            )
            .catch(errors => {
            // react on errors.
                console.log(errors);
            });

            setInterval(function () {
            // pymChild.sendHeight();
            }, 3000);
    }, // FIN INIT

    getData: function (datos, datosIllus) {
        infosTotal = [];
        illusTotal = [];
        let dataCuad;
        let datoInfCero = datos[0].type;
        let datoIllCero = datosIllus[0].type;

        if(datoInfCero.includes("info")){
            for (let inf = 0; inf < datos.length; inf++) {
                let tipo = datos[inf].type;
                if(tipo == "info"){
                    dataCuad = {
                        id: "info" + inf,
                        type: datos[inf].type,
                        titulo: datos[inf].titulo,
                        bajada: datos[inf].bajada,
                        seccion: datos[inf].seccion,
                        link: datos[inf].link,
                        video: datos[inf].video,
                        imagen: datos[inf].imagen,
                        skills: datos[inf].skills,
                        anio: datos[inf].anio
                    };
                    infosTotal.push(dataCuad)
                }
            }
        } else {
            for (let ill = 0; ill < datosIllus.length; ill++) {
                let tipo = datosIllus[ill].type;
                if(tipo == "illus"){
                    dataCuad = {
                        id: "illus" + ill,
                        type: datosIllus[ill].type,
                        titulo: datosIllus[ill].titulo,
                        bajada: datosIllus[ill].bajada,
                        seccion: datosIllus[ill].seccion,
                        link: datosIllus[ill].link,
                        video: datosIllus[ill].video,
                        imagen: datosIllus[ill].imagen,
                        skills: datosIllus[ill].skills,
                        anio: datosIllus[ill].anio
                    };
                    illusTotal.push(dataCuad)
                }
            }
        }
    },

    classLetters: function (idClass) {
        let tempLetra = '';
        let ya = [];
        let letraClase = '';
        let idCla = $(idClass).attr("id");
        let cla = $(idClass).attr("class");
        let sinC = cla.replace(/[áéíóúüñcid\s\(\)\(.)\(+)\(*)\(,)/]/g, '');

        if (idCla == "nombreMob" && ancho < 640) {
            let idNR = $("#nombreMob");
            cla = "nr";
            $(idClass).removeClass("nicolas-rivera");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
            if ($(idClass).hasClass("active")){
                $(idCla).innerHTML("");
                tempLetra = `<img alt="n" class="n" src="https://www.nicolasrivera.com.ar/img/alf/nB.svg"/><img alt="r" class="r" src="https://www.nicolasrivera.com.ar/img/alf/rB.svg"/>`;
            }
        } else if (idCla == "infosTit" && ancho < 640) {
            cla = "works";
            $(idClass).removeClass("infographics");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
        } else if (idCla == "illusTit" && ancho < 640) {
            cla = "illus";
            $(idClass).removeClass("illustrations");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
        } else if (idCla == "infosinBarreras" || idCla == "infothames" ||idCla == "infoetiquetado" ||idCla == "infolistasPaso" ||idCla == "infocopaAmerica21" ||idCla == "infovacunas" ||idCla == "infomonitor" ||idCla == "infoadopciones" ||idCla == "infomarsPerseveranceB" ||idCla == "infovirusColectivo" || idCla == "infovirusFiestas" || idCla == "infomaradonaFrases" || idCla == "infomessi700" || idCla == "infotablaCoronavirus" || idCla == "infofrasesSesiones1" || idCla == "infoespecial2020" || idCla == "infocompras100pe" || idCla == "infofixtureAmer20" || idCla == "infoescuelas2019" || idCla == "infodebatePresi2019" || idCla == "infopaso2019" || idCla == "infoluna50B" || idCla == "infocuras" || idCla == "infosubAra" || idCla == "infotronos2019" || idCla == "infojohnoliver" || idCla == "infog20favor" || idCla == "infoarmaBoca" || idCla == "infofinalLibertadores" || idCla == "infojjooJuventud" || idCla == "infomejorGol" || idCla == "infopuntajeLN" || idCla == "infoArgCroacia" || idCla == "infoestadios" || idCla == "infoelegiArg" || idCla == "infolugares" || idCla == "infodesaparecidos" || idCla == "infosubAraResumen" || idCla == "infogameTronos" || idCla == "infocalcEliminatorias" || idCla == "inforugbyB" || idCla == "infomexico86" || idCla == "infostarwars") {
            cla = sinC;
            letraClase = cla.split('');
        } else {
            cla = $(idClass).attr("class");
            letraClase = cla.split('');
        }

        $.each(letraClase, function (key, value) {
            let letra = letraClase[key];
            if($.inArray(value, ya) === -1) {
                ya.push(value);
                if (letra == "-"){
                    tempLetra = `<img alt="space" class="espacio" src="https://www.nicolasrivera.com.ar/img/alf/espacio.svg"/>`;
                } else {
                    tempLetra = `<img alt="${letra}" class="${letra}" src="https://www.nicolasrivera.com.ar/img/alf/${letra}.svg"/>`;
                }
                $("#"+idCla).append(tempLetra);
            } else {
                tempLetra = `<img alt="${letra}" class="${letra}2" src="https://www.nicolasrivera.com.ar/img/alf/${letra}.svg"/>`;
                $("#"+idCla).append(tempLetra);
            }
        });
    },

    clickSelect: function(datos){
        let ulOpts = $("#opts");
        let ulOptsMob = $("#optsMob");
        let optChoose = $("#opts .choose");
        let optChooseMob = $("#optsMob .choose");
        let opt = $("#opts .opt");
        let filterValue;
        let $gridInfos = $('#infosLand');

        if (ancho > 640){
            $gridInfos = $('#infosLand').isotope({
                itemSelector: '.info',
                layoutMode: 'masonry',
                percentPosition: true,
                masonry: {
                    columnWidth: '.info'
                },
                hiddenStyle: {
                    opacity: 0
                  },
                  visibleStyle: {
                    opacity: 1
                  }
            });
        } else {
            $gridInfos = $('#infosLand').isotope({
                itemSelector: '.info',
                layoutMode: 'fitRows',
                percentPosition: true,
                masonry: {
                    columnWidth: '.info'
                },
                hiddenStyle: {
                    opacity: 0
                },
                visibleStyle: {
                    opacity: 1
                }
            });

        }
        optChoose.on("click", function(){
            ulOpts.toggleClass("open");
            $(bigSel).addClass("active");
        })
        optChooseMob.on("click", function(){
            ulOptsMob.toggleClass("open");
            $(bigSel).addClass("active");
        })
        ulOpts.on('click', '.opt', function () { /// / Agrega o saca la clase active de los botones
            // ulOpts.css("height", "294px");
            ulOpts.toggleClass("open");
            $(bigSel).toggleClass("active");
            // ulOpts.css("padding-top", "45px");
            optChoose.css("opacity", 0);
            let botones = document.querySelectorAll('.opt');
            botones.forEach(function (item) {
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            let sortByValue = $(this).attr('data-sort-by'); //* ** Este valor tiene que coincidir con el setup del getSortData del isotope
            $gridInfos.isotope({filter: sortByValue});
            let elems = $gridInfos.isotope('getFilteredItemElements');
            infosFiltered = [];
            let check;
            for (let f = 0; f < elems.length; f++){
                let idEl = $(elems[f]).attr("id");
                let classEl = $(elems[f]).attr("class");
                let el = $(elems[f]).attr("id").replace("info", "");
                let infoFiltrada = {id: idEl, idNum: el};
                // infosFiltered.push(infoFiltrada);
                check = infosTotal.find(x => x.id === idEl);
                infosFiltered.push(check);
                // if(check){
                //     console.log("ES IGUAL", infosFiltered[f]["id"], check, check.bajada)
                // } else {
                //     console.log("NOOO ES IGUAL", infosFiltered[f]["id"], infosTotal[f])
                // }
                // if (classEl.includes("info")){
                //     console.log("ALALLALALA")
                //     app.abre(".info", infosFiltered);
                // } else {
                //     app.abre(".illus", datos);
                // }
            }
            // $(bigSel).removeClass("active");

            // filtra = true;
            // app.abre(".info", infosFiltered);
            // app.bigActive(infosFiltered, check.id);

        });
        ulOptsMob.on('click', '.opt', function () { /// / Agrega o saca la clase active de los botones
            // ulOpts.css("height", "294px");
            ulOptsMob.toggleClass("open");
            $(bigSel).toggleClass("active");
            // ulOptsMob.css("margin-top", "45px");
            optChoose.css("opacity", 0);
            let botones = document.querySelectorAll('.opt');
            botones.forEach(function (item) {
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            let sortByValue = $(this).attr('data-sort-by'); //* ** Este valor tiene que coincidir con el setup del getSortData del isotope
            $gridInfos.isotope({filter: sortByValue});
            filtra = true;
          });

    },
    armaLineas: function(){
        let ancho = $(window).width();
        let alto = $(window).height();
        let superficie = (ancho / 20) * (alto / 20);
        let fondo = document.getElementById("hiLand");
        let linea = `<div class='line' style='background-color:`+ randomOpacity() +`; height:100vh;'></div>`;

        function randomOpacity() {
            let color;

            for (let c = 0; c <= 255; c++) {
                let col1 = Math.floor(Math.random() * 256);
                let col2 = Math.floor(Math.random() * 256);
                let col3 = Math.floor(Math.random() * 256);
                let opa = Math.random().toFixed(1);
                color = `rgba(` + col1 + `,` + col2 + `,` + col3 + `,` + opa + `)`;
            }
            setInterval(3000);

            return color;
        }
        let num = 0;

        function setProperty() {
            let circNum = Math.floor(Math.random() * 100);
            let lineNum = Math.floor(Math.random() * 200);
            let divLineCirc = lineNum / circNum;
            let topTria = Math.floor(Math.random() * 150);
            let topNumber = Math.floor(Math.random() * 200);
            let widthLine = Math.floor(Math.random() * 10);
            let widthtria = Math.floor(Math.random() * 80);
            let widthCirc = Math.floor(Math.random() * 50);
            let topCirc = Math.floor(Math.random() * 100);
            num++;

            let time = Math.random();
            let timeRandom = Math.random();
            let circ = document.createElement('div');
            circ.id = 'circ' + num;
            circ.setAttribute("class", "circ");
            circ.style.width = widthCirc + "px";
            circ.style.height = widthCirc + "px";
            circ.style.backgroundColor = randomOpacity();
            circ.style.left = time * topNumber + "%";
            circ.style.setProperty('--top', topCirc + "%");

            let line = document.createElement('div');
            line.id = 'line' + num;
            line.setAttribute("class", "line");
            line.style.width = widthLine + "px";
            line.style.height = "100vh";
            line.style.backgroundColor = randomOpacity();
            line.style.left = time * topNumber + "%";

            if (Math.round(divLineCirc) == 2){
                let tria = document.createElement('div');
                tria.id = 'tria' + num;
                tria.setAttribute("class", "tria");
                tria.style.width = widthtria + "px";
                tria.style.height = widthtria + "px";
                tria.style.left = time * topNumber + "%";
                tria.style.setProperty('--top', topTria + "%");
                tria.style.boxShadow = "15px 15px 0 0 " + randomOpacity();
                $("#hiLand").append(tria);
            } else {
                $("#hiLand").append(circ);
            }

            $("#hiLand").append(line);
        }

        let appendDuration = Math.random();
        setProperty(appendDuration);
        setInterval(setProperty, 1000);
    },

    armado: function(datos, cont){
        let contenedor = $(cont).attr("id");

        let info = '';
        for ( let i = 0; i < datos.length; i++ ) {
            let titulo = datos[i].titulo;
            let anio = datos[i].anio;
            let bajada = datos[i].bajada;
            let link = datos[i].link;
            let seccion = datos[i].seccion;
            let seccionOk = seccion.replace(",", "");
            let skills = datos[i].skills;
            let video = datos[i].video;
            let imagen = datos[i].imagen;
            let imagenExt = imagen.split(".");
            let imageSinExt = imagenExt[0].replace(/[áéíóúüñ-\s\(\)\(&)\(-)\(_)\(*)\(,)/]/g, '');

            if (contenedor == "infosLand"){
                info += `<a title="link to a work" class="linkBig" href=${link} target="_blank">
                <div id="info${i}" class="info ${seccionOk}">`;
                if(video == "si") {
                    info += `<video class="imgInfo vid" playsinline autoplay muted loop>
                        <source src="https://www.nicolasrivera.com.ar/img/infos/${imagen}.mp4" type="video/mp4">
                        <source src="https://www.nicolasrivera.com.ar/img/infos/${imagen}.ogg" type="video/ogg">
                        Your browser does not support the video tag.
                    </video>`;
                } else {
                    info += `<div class="imgInfo" style="background-image:url(https://www.nicolasrivera.com.ar/img/infos/${imagen}.jpg)"></div>`;
                }
                info += `<div class="infoInfo">
                <div id="info${imageSinExt}" class="c${anio}"></div>
                <h2 class="titu">${titulo}</h2>
                <p class="descInfo">${bajada}</p>
                <p class="link">SEE MORE</p>
                <p class="anio">${skills}</p>
                </div>
                </div></a>`;
            } else {
                if (link) {
                    info += `<a title="link to an illustration" class="linkBig" href=${link} target="_blank">
                        <div id="illus${i}" class="illus">`;
                        if(video == "si") {
                            info += `<video class="imgIllus" playsinline autoplay muted loop>
                                <source src="https://www.nicolasrivera.com.ar/img/illus/${imagen}.mp4" type="video/mp4">
                                <source src="https://www.nicolasrivera.com.ar/img/illus/${imagen}.ogg" type="video/ogg">
                                Your browser does not support the video tag.
                            </video>`;
                        } else {
                            info += `<div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/${imagen}.jpg)"></div>`;
                        }
                        info += `<div class="infoIllus">
                                <h2 class="titu">${titulo}</h2>
                                <h2 class="descIllus">${bajada}</h2>
                                <p class="link">SEE MORE</p>
                                <p class="anio">${skills}</p>
                            </div>
                        </div></a>`;
                } else {
                    info += `<div id="illus${i}" class="illus">`;
                    if(video == "si") {
                        info += `<video class="imgIllus" playsinline autoplay muted loop>
                            <source src="https://www.nicolasrivera.com.ar/img/illus/${imagen}.mp4" type="video/mp4">
                            <source src="https://www.nicolasrivera.com.ar/img/illus/${imagen}.ogg" type="video/ogg">
                            Your browser does not support the video tag.
                        </video>`;
                    } else {
                        info += `<div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/${imagen}.jpg)"></div>`;
                    }
                    info += `<div class="infoIllus">
                            <h2 class="titu">${titulo}</h2>
                            <h2 class="descIllus">${bajada}</h2>
                            <p class="anio">${skills}</p>
                        </div>
                    </div>`;
                }
            }
        }
        $(cont).append(info);
    },
    tocaBody: function(idSelec, classSel){

        if (idSelec === "#bigBig") {
            $(idSelec).click(function() {
                $(idSelec).removeClass(classSel);
                $("#verBig").removeClass(classSel);
                $("#bigSel").removeClass(classSel);
            })
        }
        if (idSelec === "#bigMenu") {
            $(idSelec).click(function() {
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $('#nav-icon3').toggleClass('open');
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
                $(idSelec).removeClass(classSel);
            })
        }
        if (idSelec === "#bigSel") {
            $(idSelec).click(function() {
                $("#opts").removeClass("open");
                $("#optsMob").removeClass("open");
                $("#bigBig").removeClass(classSel);
                $("#verBig").removeClass(classSel);
                $(idSelec).removeClass(classSel);
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            })
        }
    },

    abre: function (cuad, datos) {
        let idCuad = '';
        let idNum;
        let imageOpen;
        $(cuad).click(function () {
            idCuad = $(this).attr("id");
            $("#menu").removeClass("active");
            $("#opts").removeClass("open");
            $("#optsMob").removeClass("open");
            $("#nombreMob").removeClass("active");
            $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
            $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            $(bigSel).removeClass("active");
            $(bigBig).removeClass("active");
            $(verBig).removeClass("active");
            idNum = idCuad.replace("illus", "");
            app.bigActive(datos, idCuad);
            $("#illusSel"+idNum).addClass("active");
            $(bigBig).addClass("active");
            $(verBig).addClass("active");
            imageOpen = $(".container #verBig.active #illusSel"+idNum+".active .imgIllus");

            if (ancho > 500){
                $(verBig).css("width", anchoSel + "px");
                $(verBig).css("height", altoSel + "px");
                $(verBig).css("left", (ancho - anchoSel) / 2 + "px");
                $(verBig).css("top", (alto - altoSel) / 2 + "px");
                $(imageOpen).css("height", altoSel + "px");
            }
        })

    },
    bigActive: function(datos, idCuad) {
        let tempSel = '';
        let idNum = 0;
        let idNumFil = 0;
        let titulo;
        let bajada;
        let link;
        let skills;
        let imagen;
        if (idCuad.includes("info")){
            idNum = idCuad.replace("info", "");
        } else {
            idNum = idCuad.replace("illus", "");
        }
        $(".contBig").removeClass("active");

        titulo = datos[idNum].titulo;
        bajada = datos[idNum].bajada;
        link = datos[idNum].link;
        skills = datos[idNum].skills;
        imagen = datos[idNum].imagen;
        tempSel += `<div id="illusSel${idNum}" class="contBig">
                    <div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/${imagen})"></div>
                    <div class="datosIllus"><div id="bots"><div class="cerrar" id="cierra"></div><div class="bot" id="menos"></div><div class="bot" id="mas"></div></div>
                        <h3 class="tituIllus">${titulo}</h3>
                        <p class="descInfo">${bajada}</p>
                        <p class="skills">${skills}</p>
                    </div>
                </div>`;
            $(verBig).html(tempSel);

        if (idNum == 0) {
            $("#menos").addClass("no");
        }
        if (idNum == datos.length - 1) {
            $("#mas").addClass("no");
        }
        app.masMenos(datos, idNum);
        app.cierra(idNum);
    },
    masMenos: function (datos, num) {
        let botMas = $("#mas");
        let botMenos = $("#menos");

        $(botMas).click(function () {
            let padre = this.parentElement.parentElement.parentElement.id;

            $("#illusSel"+num).removeClass("active");
            $(".contBig").removeClass("active");
            num++;
            if ( num < datos.length) {
                app.bigActive(datos, "illus"+num);
                $("#illusSel"+num).addClass("active");
            }
            $(botMas).css("opacity", 0);
            let imgIllus = $(".container #verBig.active #illusSel"+ num +".active .imgIllus");
            $(imgIllus).css("height", altoSel + "px");
        })
        $(botMenos).click(function (){
            let padre = this.parentElement.parentElement.parentElement.id;
            $("#illusSel"+num).removeClass("active");
            $(".contBig").removeClass("active");
            num--;

            app.bigActive(datos, "illus"+num);
            $("#illusSel"+num).addClass("active");
            if (num == 0) {
                $(botMenos).addClass("no");
            }

            let imgIllus = $(".container #verBig.active #illusSel"+ num +".active .imgIllus");
            $(imgIllus).css("height", altoSel + "px");
        })
    },
    cierra: function (idVer) {
        let close = document.getElementById("cierra");
        $(close).click(function() {
            $(idVer).removeClass("active");
            $(bigSel).removeClass("active");
            $(bigBig).removeClass("active");
            $(verBig).removeClass("active");
        })

    },

    clickBoton: function () {
        let botHi = document.getElementById("hola");
        let botInfos = document.getElementById("infos");
        let botIllus = document.getElementById("illus");
        let botHiMob = document.getElementById("holaMob");
        let botInfosMob = document.getElementById("infosMob");
        let botIllusMob = document.getElementById("illusMob");
        let botTop = document.getElementById("top");
        let landInfos = document.getElementById("infosLand");
        let landHi = document.getElementById("hiLand");
        let selInfos = document.getElementById("sel");
        let selInfosMob = document.getElementById("selMob");
        let landIllus = document.getElementById("illusLand");
        let posInfos = landInfos.getBoundingClientRect();
        let posIllus = landIllus.getBoundingClientRect();
        let porcen = ancho - (100)

        botTop.onclick = function (event) { // Click en boton del footer
            let href = $(this).attr('href');
            let hash = href.substr(href.indexOf('#'));

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'linear', function() {
              });
            }
            setTimeout(function () {
            }, 1500);

        } // End click
        botIllus.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "none");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'linear', function() {
              });
            }
            setTimeout(function () {
            }, 1500);

        } // End click
        botIllusMob.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#optsMob").removeClass("open");
            $(selInfosMob).css("display", "none");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'linear', function() {
              });
            }
            setTimeout(function () {
            }, 1500);
            setTimeout(function() {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        } // End click

        botHi.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "none");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'swing', function() {
              });

            }
            setTimeout(function () {
            }, 1500);
        } // End click
        botHiMob.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#optsMob").removeClass("open");
            $(selInfosMob).css("display", "none");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'swing', function() {
              });

            }
            setTimeout(function () {
            }, 1500);
            setTimeout(function() {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        } // End click
        botInfos.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "inline");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'swing', function() {
              });

            }
            setTimeout(function () {
            }, 1500);
        } // End click
        botInfosMob.onclick = function (event) { // Click en botones del nav
            let href = $(this).children().attr('href');
            let hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $(selInfosMob).css("display", "inline");

            if (this.hash !== '') {
              event.preventDefault();
              var body = $("html, body");
              let offs = -150;
              if(ancho < 640){
                offs = -100;
              }
              body.stop().animate({scrollTop: ($(hash).offset().top) + offs }, 1000, 'swing', function() {
              });

            }
            setTimeout(function () {
            }, 1500);
            setTimeout(function() {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        } // End click
    },

    clickHambur: function () {
        let tempLetra = '';

        $(document).ready(function(){
            $('#nav-icon3').click(function(){
                $(this).toggleClass('open');
                $("#menu").toggleClass("active");
                $("#nombreMob").toggleClass("active");
                $("#bigMenu").toggleClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");

                if ($("#menu").hasClass('active')){
                    $("#nombreMob.nr.active .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/nB.svg");
                    $("#nombreMob.nr.active .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/rB.svg");
                }
            });
        });
    },
}

app.init();

window.onload = function () {
  /** resize app */
  let doit;
  window.onresize = function () {
    clearTimeout(doit);
    doit = setTimeout(() => { console.log('resize app'); }, 200);
  };
};
