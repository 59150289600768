(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

module.exports = require('./lib/axios');

},{"./lib/axios":3}],2:[function(require,module,exports){
'use strict';

var utils = require('./../utils');
var settle = require('./../core/settle');
var buildURL = require('./../helpers/buildURL');
var buildFullPath = require('../core/buildFullPath');
var parseHeaders = require('./../helpers/parseHeaders');
var isURLSameOrigin = require('./../helpers/isURLSameOrigin');
var createError = require('../core/createError');

module.exports = function xhrAdapter(config) {
  return new Promise(function dispatchXhrRequest(resolve, reject) {
    var requestData = config.data;
    var requestHeaders = config.headers;

    if (utils.isFormData(requestData)) {
      delete requestHeaders['Content-Type']; // Let the browser set it
    }

    var request = new XMLHttpRequest();

    // HTTP basic authentication
    if (config.auth) {
      var username = config.auth.username || '';
      var password = config.auth.password || '';
      requestHeaders.Authorization = 'Basic ' + btoa(username + ':' + password);
    }

    var fullPath = buildFullPath(config.baseURL, config.url);
    request.open(config.method.toUpperCase(), buildURL(fullPath, config.params, config.paramsSerializer), true);

    // Set the request timeout in MS
    request.timeout = config.timeout;

    // Listen for ready state
    request.onreadystatechange = function handleLoad() {
      if (!request || request.readyState !== 4) {
        return;
      }

      // The request errored out and we didn't get a response, this will be
      // handled by onerror instead
      // With one exception: request that using file: protocol, most browsers
      // will return status as 0 even though it's a successful request
      if (request.status === 0 && !(request.responseURL && request.responseURL.indexOf('file:') === 0)) {
        return;
      }

      // Prepare the response
      var responseHeaders = 'getAllResponseHeaders' in request ? parseHeaders(request.getAllResponseHeaders()) : null;
      var responseData = !config.responseType || config.responseType === 'text' ? request.responseText : request.response;
      var response = {
        data: responseData,
        status: request.status,
        statusText: request.statusText,
        headers: responseHeaders,
        config: config,
        request: request
      };

      settle(resolve, reject, response);

      // Clean up request
      request = null;
    };

    // Handle browser request cancellation (as opposed to a manual cancellation)
    request.onabort = function handleAbort() {
      if (!request) {
        return;
      }

      reject(createError('Request aborted', config, 'ECONNABORTED', request));

      // Clean up request
      request = null;
    };

    // Handle low level network errors
    request.onerror = function handleError() {
      // Real errors are hidden from us by the browser
      // onerror should only fire if it's a network error
      reject(createError('Network Error', config, null, request));

      // Clean up request
      request = null;
    };

    // Handle timeout
    request.ontimeout = function handleTimeout() {
      var timeoutErrorMessage = 'timeout of ' + config.timeout + 'ms exceeded';
      if (config.timeoutErrorMessage) {
        timeoutErrorMessage = config.timeoutErrorMessage;
      }
      reject(createError(timeoutErrorMessage, config, 'ECONNABORTED', request));

      // Clean up request
      request = null;
    };

    // Add xsrf header
    // This is only done if running in a standard browser environment.
    // Specifically not if we're in a web worker, or react-native.
    if (utils.isStandardBrowserEnv()) {
      var cookies = require('./../helpers/cookies');

      // Add xsrf header
      var xsrfValue = (config.withCredentials || isURLSameOrigin(fullPath)) && config.xsrfCookieName ? cookies.read(config.xsrfCookieName) : undefined;

      if (xsrfValue) {
        requestHeaders[config.xsrfHeaderName] = xsrfValue;
      }
    }

    // Add headers to the request
    if ('setRequestHeader' in request) {
      utils.forEach(requestHeaders, function setRequestHeader(val, key) {
        if (typeof requestData === 'undefined' && key.toLowerCase() === 'content-type') {
          // Remove Content-Type if data is undefined
          delete requestHeaders[key];
        } else {
          // Otherwise add header to the request
          request.setRequestHeader(key, val);
        }
      });
    }

    // Add withCredentials to request if needed
    if (!utils.isUndefined(config.withCredentials)) {
      request.withCredentials = !!config.withCredentials;
    }

    // Add responseType to request if needed
    if (config.responseType) {
      try {
        request.responseType = config.responseType;
      } catch (e) {
        // Expected DOMException thrown by browsers not compatible XMLHttpRequest Level 2.
        // But, this can be suppressed for 'json' type as it can be parsed by default 'transformResponse' function.
        if (config.responseType !== 'json') {
          throw e;
        }
      }
    }

    // Handle progress if needed
    if (typeof config.onDownloadProgress === 'function') {
      request.addEventListener('progress', config.onDownloadProgress);
    }

    // Not all browsers support upload events
    if (typeof config.onUploadProgress === 'function' && request.upload) {
      request.upload.addEventListener('progress', config.onUploadProgress);
    }

    if (config.cancelToken) {
      // Handle cancellation
      config.cancelToken.promise.then(function onCanceled(cancel) {
        if (!request) {
          return;
        }

        request.abort();
        reject(cancel);
        // Clean up request
        request = null;
      });
    }

    if (requestData === undefined) {
      requestData = null;
    }

    // Send the request
    request.send(requestData);
  });
};

},{"../core/buildFullPath":9,"../core/createError":10,"./../core/settle":14,"./../helpers/buildURL":18,"./../helpers/cookies":20,"./../helpers/isURLSameOrigin":22,"./../helpers/parseHeaders":24,"./../utils":26}],3:[function(require,module,exports){
'use strict';

var utils = require('./utils');
var bind = require('./helpers/bind');
var Axios = require('./core/Axios');
var mergeConfig = require('./core/mergeConfig');
var defaults = require('./defaults');

/**
 * Create an instance of Axios
 *
 * @param {Object} defaultConfig The default config for the instance
 * @return {Axios} A new instance of Axios
 */
function createInstance(defaultConfig) {
  var context = new Axios(defaultConfig);
  var instance = bind(Axios.prototype.request, context);

  // Copy axios.prototype to instance
  utils.extend(instance, Axios.prototype, context);

  // Copy context to instance
  utils.extend(instance, context);

  return instance;
}

// Create the default instance to be exported
var axios = createInstance(defaults);

// Expose Axios class to allow class inheritance
axios.Axios = Axios;

// Factory for creating new instances
axios.create = function create(instanceConfig) {
  return createInstance(mergeConfig(axios.defaults, instanceConfig));
};

// Expose Cancel & CancelToken
axios.Cancel = require('./cancel/Cancel');
axios.CancelToken = require('./cancel/CancelToken');
axios.isCancel = require('./cancel/isCancel');

// Expose all/spread
axios.all = function all(promises) {
  return Promise.all(promises);
};
axios.spread = require('./helpers/spread');

module.exports = axios;

// Allow use of default import syntax in TypeScript
module.exports.default = axios;

},{"./cancel/Cancel":4,"./cancel/CancelToken":5,"./cancel/isCancel":6,"./core/Axios":7,"./core/mergeConfig":13,"./defaults":16,"./helpers/bind":17,"./helpers/spread":25,"./utils":26}],4:[function(require,module,exports){
'use strict';

/**
 * A `Cancel` is an object that is thrown when an operation is canceled.
 *
 * @class
 * @param {string=} message The message.
 */

function Cancel(message) {
  this.message = message;
}

Cancel.prototype.toString = function toString() {
  return 'Cancel' + (this.message ? ': ' + this.message : '');
};

Cancel.prototype.__CANCEL__ = true;

module.exports = Cancel;

},{}],5:[function(require,module,exports){
'use strict';

var Cancel = require('./Cancel');

/**
 * A `CancelToken` is an object that can be used to request cancellation of an operation.
 *
 * @class
 * @param {Function} executor The executor function.
 */
function CancelToken(executor) {
  if (typeof executor !== 'function') {
    throw new TypeError('executor must be a function.');
  }

  var resolvePromise;
  this.promise = new Promise(function promiseExecutor(resolve) {
    resolvePromise = resolve;
  });

  var token = this;
  executor(function cancel(message) {
    if (token.reason) {
      // Cancellation has already been requested
      return;
    }

    token.reason = new Cancel(message);
    resolvePromise(token.reason);
  });
}

/**
 * Throws a `Cancel` if cancellation has been requested.
 */
CancelToken.prototype.throwIfRequested = function throwIfRequested() {
  if (this.reason) {
    throw this.reason;
  }
};

/**
 * Returns an object that contains a new `CancelToken` and a function that, when called,
 * cancels the `CancelToken`.
 */
CancelToken.source = function source() {
  var cancel;
  var token = new CancelToken(function executor(c) {
    cancel = c;
  });
  return {
    token: token,
    cancel: cancel
  };
};

module.exports = CancelToken;

},{"./Cancel":4}],6:[function(require,module,exports){
'use strict';

module.exports = function isCancel(value) {
  return !!(value && value.__CANCEL__);
};

},{}],7:[function(require,module,exports){
'use strict';

var utils = require('./../utils');
var buildURL = require('../helpers/buildURL');
var InterceptorManager = require('./InterceptorManager');
var dispatchRequest = require('./dispatchRequest');
var mergeConfig = require('./mergeConfig');

/**
 * Create a new instance of Axios
 *
 * @param {Object} instanceConfig The default config for the instance
 */
function Axios(instanceConfig) {
  this.defaults = instanceConfig;
  this.interceptors = {
    request: new InterceptorManager(),
    response: new InterceptorManager()
  };
}

/**
 * Dispatch a request
 *
 * @param {Object} config The config specific for this request (merged with this.defaults)
 */
Axios.prototype.request = function request(config) {
  /*eslint no-param-reassign:0*/
  // Allow for axios('example/url'[, config]) a la fetch API
  if (typeof config === 'string') {
    config = arguments[1] || {};
    config.url = arguments[0];
  } else {
    config = config || {};
  }

  config = mergeConfig(this.defaults, config);

  // Set config.method
  if (config.method) {
    config.method = config.method.toLowerCase();
  } else if (this.defaults.method) {
    config.method = this.defaults.method.toLowerCase();
  } else {
    config.method = 'get';
  }

  // Hook up interceptors middleware
  var chain = [dispatchRequest, undefined];
  var promise = Promise.resolve(config);

  this.interceptors.request.forEach(function unshiftRequestInterceptors(interceptor) {
    chain.unshift(interceptor.fulfilled, interceptor.rejected);
  });

  this.interceptors.response.forEach(function pushResponseInterceptors(interceptor) {
    chain.push(interceptor.fulfilled, interceptor.rejected);
  });

  while (chain.length) {
    promise = promise.then(chain.shift(), chain.shift());
  }

  return promise;
};

Axios.prototype.getUri = function getUri(config) {
  config = mergeConfig(this.defaults, config);
  return buildURL(config.url, config.params, config.paramsSerializer).replace(/^\?/, '');
};

// Provide aliases for supported request methods
utils.forEach(['delete', 'get', 'head', 'options'], function forEachMethodNoData(method) {
  /*eslint func-names:0*/
  Axios.prototype[method] = function (url, config) {
    return this.request(utils.merge(config || {}, {
      method: method,
      url: url
    }));
  };
});

utils.forEach(['post', 'put', 'patch'], function forEachMethodWithData(method) {
  /*eslint func-names:0*/
  Axios.prototype[method] = function (url, data, config) {
    return this.request(utils.merge(config || {}, {
      method: method,
      url: url,
      data: data
    }));
  };
});

module.exports = Axios;

},{"../helpers/buildURL":18,"./../utils":26,"./InterceptorManager":8,"./dispatchRequest":11,"./mergeConfig":13}],8:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

function InterceptorManager() {
  this.handlers = [];
}

/**
 * Add a new interceptor to the stack
 *
 * @param {Function} fulfilled The function to handle `then` for a `Promise`
 * @param {Function} rejected The function to handle `reject` for a `Promise`
 *
 * @return {Number} An ID used to remove interceptor later
 */
InterceptorManager.prototype.use = function use(fulfilled, rejected) {
  this.handlers.push({
    fulfilled: fulfilled,
    rejected: rejected
  });
  return this.handlers.length - 1;
};

/**
 * Remove an interceptor from the stack
 *
 * @param {Number} id The ID that was returned by `use`
 */
InterceptorManager.prototype.eject = function eject(id) {
  if (this.handlers[id]) {
    this.handlers[id] = null;
  }
};

/**
 * Iterate over all the registered interceptors
 *
 * This method is particularly useful for skipping over any
 * interceptors that may have become `null` calling `eject`.
 *
 * @param {Function} fn The function to call for each interceptor
 */
InterceptorManager.prototype.forEach = function forEach(fn) {
  utils.forEach(this.handlers, function forEachHandler(h) {
    if (h !== null) {
      fn(h);
    }
  });
};

module.exports = InterceptorManager;

},{"./../utils":26}],9:[function(require,module,exports){
'use strict';

var isAbsoluteURL = require('../helpers/isAbsoluteURL');
var combineURLs = require('../helpers/combineURLs');

/**
 * Creates a new URL by combining the baseURL with the requestedURL,
 * only when the requestedURL is not already an absolute URL.
 * If the requestURL is absolute, this function returns the requestedURL untouched.
 *
 * @param {string} baseURL The base URL
 * @param {string} requestedURL Absolute or relative URL to combine
 * @returns {string} The combined full path
 */
module.exports = function buildFullPath(baseURL, requestedURL) {
  if (baseURL && !isAbsoluteURL(requestedURL)) {
    return combineURLs(baseURL, requestedURL);
  }
  return requestedURL;
};

},{"../helpers/combineURLs":19,"../helpers/isAbsoluteURL":21}],10:[function(require,module,exports){
'use strict';

var enhanceError = require('./enhanceError');

/**
 * Create an Error with the specified message, config, error code, request and response.
 *
 * @param {string} message The error message.
 * @param {Object} config The config.
 * @param {string} [code] The error code (for example, 'ECONNABORTED').
 * @param {Object} [request] The request.
 * @param {Object} [response] The response.
 * @returns {Error} The created error.
 */
module.exports = function createError(message, config, code, request, response) {
  var error = new Error(message);
  return enhanceError(error, config, code, request, response);
};

},{"./enhanceError":12}],11:[function(require,module,exports){
'use strict';

var utils = require('./../utils');
var transformData = require('./transformData');
var isCancel = require('../cancel/isCancel');
var defaults = require('../defaults');

/**
 * Throws a `Cancel` if cancellation has been requested.
 */
function throwIfCancellationRequested(config) {
  if (config.cancelToken) {
    config.cancelToken.throwIfRequested();
  }
}

/**
 * Dispatch a request to the server using the configured adapter.
 *
 * @param {object} config The config that is to be used for the request
 * @returns {Promise} The Promise to be fulfilled
 */
module.exports = function dispatchRequest(config) {
  throwIfCancellationRequested(config);

  // Ensure headers exist
  config.headers = config.headers || {};

  // Transform request data
  config.data = transformData(config.data, config.headers, config.transformRequest);

  // Flatten headers
  config.headers = utils.merge(config.headers.common || {}, config.headers[config.method] || {}, config.headers);

  utils.forEach(['delete', 'get', 'head', 'post', 'put', 'patch', 'common'], function cleanHeaderConfig(method) {
    delete config.headers[method];
  });

  var adapter = config.adapter || defaults.adapter;

  return adapter(config).then(function onAdapterResolution(response) {
    throwIfCancellationRequested(config);

    // Transform response data
    response.data = transformData(response.data, response.headers, config.transformResponse);

    return response;
  }, function onAdapterRejection(reason) {
    if (!isCancel(reason)) {
      throwIfCancellationRequested(config);

      // Transform response data
      if (reason && reason.response) {
        reason.response.data = transformData(reason.response.data, reason.response.headers, config.transformResponse);
      }
    }

    return Promise.reject(reason);
  });
};

},{"../cancel/isCancel":6,"../defaults":16,"./../utils":26,"./transformData":15}],12:[function(require,module,exports){
'use strict';

/**
 * Update an Error with the specified config, error code, and response.
 *
 * @param {Error} error The error to update.
 * @param {Object} config The config.
 * @param {string} [code] The error code (for example, 'ECONNABORTED').
 * @param {Object} [request] The request.
 * @param {Object} [response] The response.
 * @returns {Error} The error.
 */

module.exports = function enhanceError(error, config, code, request, response) {
  error.config = config;
  if (code) {
    error.code = code;
  }

  error.request = request;
  error.response = response;
  error.isAxiosError = true;

  error.toJSON = function () {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code
    };
  };
  return error;
};

},{}],13:[function(require,module,exports){
'use strict';

var utils = require('../utils');

/**
 * Config-specific merge-function which creates a new config-object
 * by merging two configuration objects together.
 *
 * @param {Object} config1
 * @param {Object} config2
 * @returns {Object} New object resulting from merging config2 to config1
 */
module.exports = function mergeConfig(config1, config2) {
  // eslint-disable-next-line no-param-reassign
  config2 = config2 || {};
  var config = {};

  var valueFromConfig2Keys = ['url', 'method', 'params', 'data'];
  var mergeDeepPropertiesKeys = ['headers', 'auth', 'proxy'];
  var defaultToConfig2Keys = ['baseURL', 'url', 'transformRequest', 'transformResponse', 'paramsSerializer', 'timeout', 'withCredentials', 'adapter', 'responseType', 'xsrfCookieName', 'xsrfHeaderName', 'onUploadProgress', 'onDownloadProgress', 'maxContentLength', 'validateStatus', 'maxRedirects', 'httpAgent', 'httpsAgent', 'cancelToken', 'socketPath'];

  utils.forEach(valueFromConfig2Keys, function valueFromConfig2(prop) {
    if (typeof config2[prop] !== 'undefined') {
      config[prop] = config2[prop];
    }
  });

  utils.forEach(mergeDeepPropertiesKeys, function mergeDeepProperties(prop) {
    if (utils.isObject(config2[prop])) {
      config[prop] = utils.deepMerge(config1[prop], config2[prop]);
    } else if (typeof config2[prop] !== 'undefined') {
      config[prop] = config2[prop];
    } else if (utils.isObject(config1[prop])) {
      config[prop] = utils.deepMerge(config1[prop]);
    } else if (typeof config1[prop] !== 'undefined') {
      config[prop] = config1[prop];
    }
  });

  utils.forEach(defaultToConfig2Keys, function defaultToConfig2(prop) {
    if (typeof config2[prop] !== 'undefined') {
      config[prop] = config2[prop];
    } else if (typeof config1[prop] !== 'undefined') {
      config[prop] = config1[prop];
    }
  });

  var axiosKeys = valueFromConfig2Keys.concat(mergeDeepPropertiesKeys).concat(defaultToConfig2Keys);

  var otherKeys = Object.keys(config2).filter(function filterAxiosKeys(key) {
    return axiosKeys.indexOf(key) === -1;
  });

  utils.forEach(otherKeys, function otherKeysDefaultToConfig2(prop) {
    if (typeof config2[prop] !== 'undefined') {
      config[prop] = config2[prop];
    } else if (typeof config1[prop] !== 'undefined') {
      config[prop] = config1[prop];
    }
  });

  return config;
};

},{"../utils":26}],14:[function(require,module,exports){
'use strict';

var createError = require('./createError');

/**
 * Resolve or reject a Promise based on response status.
 *
 * @param {Function} resolve A function that resolves the promise.
 * @param {Function} reject A function that rejects the promise.
 * @param {object} response The response.
 */
module.exports = function settle(resolve, reject, response) {
  var validateStatus = response.config.validateStatus;
  if (!validateStatus || validateStatus(response.status)) {
    resolve(response);
  } else {
    reject(createError('Request failed with status code ' + response.status, response.config, null, response.request, response));
  }
};

},{"./createError":10}],15:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

/**
 * Transform the data for a request or a response
 *
 * @param {Object|String} data The data to be transformed
 * @param {Array} headers The headers for the request or response
 * @param {Array|Function} fns A single function or Array of functions
 * @returns {*} The resulting transformed data
 */
module.exports = function transformData(data, headers, fns) {
  /*eslint no-param-reassign:0*/
  utils.forEach(fns, function transform(fn) {
    data = fn(data, headers);
  });

  return data;
};

},{"./../utils":26}],16:[function(require,module,exports){
(function (process){
'use strict';

var utils = require('./utils');
var normalizeHeaderName = require('./helpers/normalizeHeaderName');

var DEFAULT_CONTENT_TYPE = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

function setContentTypeIfUnset(headers, value) {
  if (!utils.isUndefined(headers) && utils.isUndefined(headers['Content-Type'])) {
    headers['Content-Type'] = value;
  }
}

function getDefaultAdapter() {
  var adapter;
  if (typeof XMLHttpRequest !== 'undefined') {
    // For browsers use XHR adapter
    adapter = require('./adapters/xhr');
  } else if (typeof process !== 'undefined' && Object.prototype.toString.call(process) === '[object process]') {
    // For node use HTTP adapter
    adapter = require('./adapters/http');
  }
  return adapter;
}

var defaults = {
  adapter: getDefaultAdapter(),

  transformRequest: [function transformRequest(data, headers) {
    normalizeHeaderName(headers, 'Accept');
    normalizeHeaderName(headers, 'Content-Type');
    if (utils.isFormData(data) || utils.isArrayBuffer(data) || utils.isBuffer(data) || utils.isStream(data) || utils.isFile(data) || utils.isBlob(data)) {
      return data;
    }
    if (utils.isArrayBufferView(data)) {
      return data.buffer;
    }
    if (utils.isURLSearchParams(data)) {
      setContentTypeIfUnset(headers, 'application/x-www-form-urlencoded;charset=utf-8');
      return data.toString();
    }
    if (utils.isObject(data)) {
      setContentTypeIfUnset(headers, 'application/json;charset=utf-8');
      return JSON.stringify(data);
    }
    return data;
  }],

  transformResponse: [function transformResponse(data) {
    /*eslint no-param-reassign:0*/
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (e) {/* Ignore */}
    }
    return data;
  }],

  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,

  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',

  maxContentLength: -1,

  validateStatus: function validateStatus(status) {
    return status >= 200 && status < 300;
  }
};

defaults.headers = {
  common: {
    'Accept': 'application/json, text/plain, */*'
  }
};

utils.forEach(['delete', 'get', 'head'], function forEachMethodNoData(method) {
  defaults.headers[method] = {};
});

utils.forEach(['post', 'put', 'patch'], function forEachMethodWithData(method) {
  defaults.headers[method] = utils.merge(DEFAULT_CONTENT_TYPE);
});

module.exports = defaults;

}).call(this,require('_process'))

},{"./adapters/http":2,"./adapters/xhr":2,"./helpers/normalizeHeaderName":23,"./utils":26,"_process":27}],17:[function(require,module,exports){
'use strict';

module.exports = function bind(fn, thisArg) {
  return function wrap() {
    var args = new Array(arguments.length);
    for (var i = 0; i < args.length; i++) {
      args[i] = arguments[i];
    }
    return fn.apply(thisArg, args);
  };
};

},{}],18:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

function encode(val) {
  return encodeURIComponent(val).replace(/%40/gi, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%20/g, '+').replace(/%5B/gi, '[').replace(/%5D/gi, ']');
}

/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted url
 */
module.exports = function buildURL(url, params, paramsSerializer) {
  /*eslint no-param-reassign:0*/
  if (!params) {
    return url;
  }

  var serializedParams;
  if (paramsSerializer) {
    serializedParams = paramsSerializer(params);
  } else if (utils.isURLSearchParams(params)) {
    serializedParams = params.toString();
  } else {
    var parts = [];

    utils.forEach(params, function serialize(val, key) {
      if (val === null || typeof val === 'undefined') {
        return;
      }

      if (utils.isArray(val)) {
        key = key + '[]';
      } else {
        val = [val];
      }

      utils.forEach(val, function parseValue(v) {
        if (utils.isDate(v)) {
          v = v.toISOString();
        } else if (utils.isObject(v)) {
          v = JSON.stringify(v);
        }
        parts.push(encode(key) + '=' + encode(v));
      });
    });

    serializedParams = parts.join('&');
  }

  if (serializedParams) {
    var hashmarkIndex = url.indexOf('#');
    if (hashmarkIndex !== -1) {
      url = url.slice(0, hashmarkIndex);
    }

    url += (url.indexOf('?') === -1 ? '?' : '&') + serializedParams;
  }

  return url;
};

},{"./../utils":26}],19:[function(require,module,exports){
'use strict';

/**
 * Creates a new URL by combining the specified URLs
 *
 * @param {string} baseURL The base URL
 * @param {string} relativeURL The relative URL
 * @returns {string} The combined URL
 */

module.exports = function combineURLs(baseURL, relativeURL) {
  return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL;
};

},{}],20:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

module.exports = utils.isStandardBrowserEnv() ?

// Standard browser envs support document.cookie
function standardBrowserEnv() {
  return {
    write: function write(name, value, expires, path, domain, secure) {
      var cookie = [];
      cookie.push(name + '=' + encodeURIComponent(value));

      if (utils.isNumber(expires)) {
        cookie.push('expires=' + new Date(expires).toGMTString());
      }

      if (utils.isString(path)) {
        cookie.push('path=' + path);
      }

      if (utils.isString(domain)) {
        cookie.push('domain=' + domain);
      }

      if (secure === true) {
        cookie.push('secure');
      }

      document.cookie = cookie.join('; ');
    },

    read: function read(name) {
      var match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
      return match ? decodeURIComponent(match[3]) : null;
    },

    remove: function remove(name) {
      this.write(name, '', Date.now() - 86400000);
    }
  };
}() :

// Non standard browser env (web workers, react-native) lack needed support.
function nonStandardBrowserEnv() {
  return {
    write: function write() {},
    read: function read() {
      return null;
    },
    remove: function remove() {}
  };
}();

},{"./../utils":26}],21:[function(require,module,exports){
'use strict';

/**
 * Determines whether the specified URL is absolute
 *
 * @param {string} url The URL to test
 * @returns {boolean} True if the specified URL is absolute, otherwise false
 */

module.exports = function isAbsoluteURL(url) {
  // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
  // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
  // by any combination of letters, digits, plus, period, or hyphen.
  return (/^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url)
  );
};

},{}],22:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

module.exports = utils.isStandardBrowserEnv() ?

// Standard browser envs have full support of the APIs needed to test
// whether the request URL is of the same origin as current location.
function standardBrowserEnv() {
  var msie = /(msie|trident)/i.test(navigator.userAgent);
  var urlParsingNode = document.createElement('a');
  var originURL;

  /**
  * Parse a URL to discover it's components
  *
  * @param {String} url The URL to be parsed
  * @returns {Object}
  */
  function resolveURL(url) {
    var href = url;

    if (msie) {
      // IE needs attribute set twice to normalize properties
      urlParsingNode.setAttribute('href', href);
      href = urlParsingNode.href;
    }

    urlParsingNode.setAttribute('href', href);

    // urlParsingNode provides the UrlUtils interface - http://url.spec.whatwg.org/#urlutils
    return {
      href: urlParsingNode.href,
      protocol: urlParsingNode.protocol ? urlParsingNode.protocol.replace(/:$/, '') : '',
      host: urlParsingNode.host,
      search: urlParsingNode.search ? urlParsingNode.search.replace(/^\?/, '') : '',
      hash: urlParsingNode.hash ? urlParsingNode.hash.replace(/^#/, '') : '',
      hostname: urlParsingNode.hostname,
      port: urlParsingNode.port,
      pathname: urlParsingNode.pathname.charAt(0) === '/' ? urlParsingNode.pathname : '/' + urlParsingNode.pathname
    };
  }

  originURL = resolveURL(window.location.href);

  /**
  * Determine if a URL shares the same origin as the current location
  *
  * @param {String} requestURL The URL to test
  * @returns {boolean} True if URL shares the same origin, otherwise false
  */
  return function isURLSameOrigin(requestURL) {
    var parsed = utils.isString(requestURL) ? resolveURL(requestURL) : requestURL;
    return parsed.protocol === originURL.protocol && parsed.host === originURL.host;
  };
}() :

// Non standard browser envs (web workers, react-native) lack needed support.
function nonStandardBrowserEnv() {
  return function isURLSameOrigin() {
    return true;
  };
}();

},{"./../utils":26}],23:[function(require,module,exports){
'use strict';

var utils = require('../utils');

module.exports = function normalizeHeaderName(headers, normalizedName) {
  utils.forEach(headers, function processHeader(value, name) {
    if (name !== normalizedName && name.toUpperCase() === normalizedName.toUpperCase()) {
      headers[normalizedName] = value;
      delete headers[name];
    }
  });
};

},{"../utils":26}],24:[function(require,module,exports){
'use strict';

var utils = require('./../utils');

// Headers whose duplicates are ignored by node
// c.f. https://nodejs.org/api/http.html#http_message_headers
var ignoreDuplicateOf = ['age', 'authorization', 'content-length', 'content-type', 'etag', 'expires', 'from', 'host', 'if-modified-since', 'if-unmodified-since', 'last-modified', 'location', 'max-forwards', 'proxy-authorization', 'referer', 'retry-after', 'user-agent'];

/**
 * Parse headers into an object
 *
 * ```
 * Date: Wed, 27 Aug 2014 08:58:49 GMT
 * Content-Type: application/json
 * Connection: keep-alive
 * Transfer-Encoding: chunked
 * ```
 *
 * @param {String} headers Headers needing to be parsed
 * @returns {Object} Headers parsed into an object
 */
module.exports = function parseHeaders(headers) {
  var parsed = {};
  var key;
  var val;
  var i;

  if (!headers) {
    return parsed;
  }

  utils.forEach(headers.split('\n'), function parser(line) {
    i = line.indexOf(':');
    key = utils.trim(line.substr(0, i)).toLowerCase();
    val = utils.trim(line.substr(i + 1));

    if (key) {
      if (parsed[key] && ignoreDuplicateOf.indexOf(key) >= 0) {
        return;
      }
      if (key === 'set-cookie') {
        parsed[key] = (parsed[key] ? parsed[key] : []).concat([val]);
      } else {
        parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
      }
    }
  });

  return parsed;
};

},{"./../utils":26}],25:[function(require,module,exports){
'use strict';

/**
 * Syntactic sugar for invoking a function and expanding an array for arguments.
 *
 * Common use case would be to use `Function.prototype.apply`.
 *
 *  ```js
 *  function f(x, y, z) {}
 *  var args = [1, 2, 3];
 *  f.apply(null, args);
 *  ```
 *
 * With `spread` this example can be re-written.
 *
 *  ```js
 *  spread(function(x, y, z) {})([1, 2, 3]);
 *  ```
 *
 * @param {Function} callback
 * @returns {Function}
 */

module.exports = function spread(callback) {
  return function wrap(arr) {
    return callback.apply(null, arr);
  };
};

},{}],26:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var bind = require('./helpers/bind');

/*global toString:true*/

// utils is a library of generic helper functions non-specific to axios

var toString = Object.prototype.toString;

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
function isArray(val) {
  return toString.call(val) === '[object Array]';
}

/**
 * Determine if a value is undefined
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
function isUndefined(val) {
  return typeof val === 'undefined';
}

/**
 * Determine if a value is a Buffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Buffer, otherwise false
 */
function isBuffer(val) {
  return val !== null && !isUndefined(val) && val.constructor !== null && !isUndefined(val.constructor) && typeof val.constructor.isBuffer === 'function' && val.constructor.isBuffer(val);
}

/**
 * Determine if a value is an ArrayBuffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an ArrayBuffer, otherwise false
 */
function isArrayBuffer(val) {
  return toString.call(val) === '[object ArrayBuffer]';
}

/**
 * Determine if a value is a FormData
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an FormData, otherwise false
 */
function isFormData(val) {
  return typeof FormData !== 'undefined' && val instanceof FormData;
}

/**
 * Determine if a value is a view on an ArrayBuffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a view on an ArrayBuffer, otherwise false
 */
function isArrayBufferView(val) {
  var result;
  if (typeof ArrayBuffer !== 'undefined' && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val);
  } else {
    result = val && val.buffer && val.buffer instanceof ArrayBuffer;
  }
  return result;
}

/**
 * Determine if a value is a String
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a String, otherwise false
 */
function isString(val) {
  return typeof val === 'string';
}

/**
 * Determine if a value is a Number
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Number, otherwise false
 */
function isNumber(val) {
  return typeof val === 'number';
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
function isObject(val) {
  return val !== null && (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object';
}

/**
 * Determine if a value is a Date
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Date, otherwise false
 */
function isDate(val) {
  return toString.call(val) === '[object Date]';
}

/**
 * Determine if a value is a File
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a File, otherwise false
 */
function isFile(val) {
  return toString.call(val) === '[object File]';
}

/**
 * Determine if a value is a Blob
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Blob, otherwise false
 */
function isBlob(val) {
  return toString.call(val) === '[object Blob]';
}

/**
 * Determine if a value is a Function
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
function isFunction(val) {
  return toString.call(val) === '[object Function]';
}

/**
 * Determine if a value is a Stream
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Stream, otherwise false
 */
function isStream(val) {
  return isObject(val) && isFunction(val.pipe);
}

/**
 * Determine if a value is a URLSearchParams object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a URLSearchParams object, otherwise false
 */
function isURLSearchParams(val) {
  return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams;
}

/**
 * Trim excess whitespace off the beginning and end of a string
 *
 * @param {String} str The String to trim
 * @returns {String} The String freed of excess whitespace
 */
function trim(str) {
  return str.replace(/^\s*/, '').replace(/\s*$/, '');
}

/**
 * Determine if we're running in a standard browser environment
 *
 * This allows axios to run in a web worker, and react-native.
 * Both environments support XMLHttpRequest, but not fully standard globals.
 *
 * web workers:
 *  typeof window -> undefined
 *  typeof document -> undefined
 *
 * react-native:
 *  navigator.product -> 'ReactNative'
 * nativescript
 *  navigator.product -> 'NativeScript' or 'NS'
 */
function isStandardBrowserEnv() {
  if (typeof navigator !== 'undefined' && (navigator.product === 'ReactNative' || navigator.product === 'NativeScript' || navigator.product === 'NS')) {
    return false;
  }
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

/**
 * Iterate over an Array or an Object invoking a function for each item.
 *
 * If `obj` is an Array callback will be called passing
 * the value, index, and complete array for each item.
 *
 * If 'obj' is an Object callback will be called passing
 * the value, key, and complete object for each property.
 *
 * @param {Object|Array} obj The object to iterate
 * @param {Function} fn The callback to invoke for each item
 */
function forEach(obj, fn) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  // Force an array if not already something iterable
  if ((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) !== 'object') {
    /*eslint no-param-reassign:0*/
    obj = [obj];
  }

  if (isArray(obj)) {
    // Iterate over array values
    for (var i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

/**
 * Accepts varargs expecting each argument to be an object, then
 * immutably merges the properties of each object and returns result.
 *
 * When multiple objects contain the same key the later object in
 * the arguments list will take precedence.
 *
 * Example:
 *
 * ```js
 * var result = merge({foo: 123}, {foo: 456});
 * console.log(result.foo); // outputs 456
 * ```
 *
 * @param {Object} obj1 Object to merge
 * @returns {Object} Result of all merge properties
 */
function merge() /* obj1, obj2, obj3, ... */{
  var result = {};
  function assignValue(val, key) {
    if (_typeof(result[key]) === 'object' && (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object') {
      result[key] = merge(result[key], val);
    } else {
      result[key] = val;
    }
  }

  for (var i = 0, l = arguments.length; i < l; i++) {
    forEach(arguments[i], assignValue);
  }
  return result;
}

/**
 * Function equal to merge with the difference being that no reference
 * to original objects is kept.
 *
 * @see merge
 * @param {Object} obj1 Object to merge
 * @returns {Object} Result of all merge properties
 */
function deepMerge() /* obj1, obj2, obj3, ... */{
  var result = {};
  function assignValue(val, key) {
    if (_typeof(result[key]) === 'object' && (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object') {
      result[key] = deepMerge(result[key], val);
    } else if ((typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object') {
      result[key] = deepMerge({}, val);
    } else {
      result[key] = val;
    }
  }

  for (var i = 0, l = arguments.length; i < l; i++) {
    forEach(arguments[i], assignValue);
  }
  return result;
}

/**
 * Extends object a by mutably adding to it the properties of object b.
 *
 * @param {Object} a The object to be extended
 * @param {Object} b The object to copy properties from
 * @param {Object} thisArg The object to bind function to
 * @return {Object} The resulting value of object a
 */
function extend(a, b, thisArg) {
  forEach(b, function assignValue(val, key) {
    if (thisArg && typeof val === 'function') {
      a[key] = bind(val, thisArg);
    } else {
      a[key] = val;
    }
  });
  return a;
}

module.exports = {
  isArray: isArray,
  isArrayBuffer: isArrayBuffer,
  isBuffer: isBuffer,
  isFormData: isFormData,
  isArrayBufferView: isArrayBufferView,
  isString: isString,
  isNumber: isNumber,
  isObject: isObject,
  isUndefined: isUndefined,
  isDate: isDate,
  isFile: isFile,
  isBlob: isBlob,
  isFunction: isFunction,
  isStream: isStream,
  isURLSearchParams: isURLSearchParams,
  isStandardBrowserEnv: isStandardBrowserEnv,
  forEach: forEach,
  merge: merge,
  deepMerge: deepMerge,
  extend: extend,
  trim: trim
};

},{"./helpers/bind":17}],27:[function(require,module,exports){
'use strict';

// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

function defaultSetTimout() {
    throw new Error('setTimeout has not been defined');
}
function defaultClearTimeout() {
    throw new Error('clearTimeout has not been defined');
}
(function () {
    try {
        if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
        } else {
            cachedSetTimeout = defaultSetTimout;
        }
    } catch (e) {
        cachedSetTimeout = defaultSetTimout;
    }
    try {
        if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
        } else {
            cachedClearTimeout = defaultClearTimeout;
        }
    } catch (e) {
        cachedClearTimeout = defaultClearTimeout;
    }
})();
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        //normal enviroments in sane situations
        return setTimeout(fun, 0);
    }
    // if setTimeout wasn't available but was latter defined
    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
        cachedSetTimeout = setTimeout;
        return setTimeout(fun, 0);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedSetTimeout(fun, 0);
    } catch (e) {
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
        } catch (e) {
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
        }
    }
}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        //normal enviroments in sane situations
        return clearTimeout(marker);
    }
    // if clearTimeout wasn't available but was latter defined
    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
        cachedClearTimeout = clearTimeout;
        return clearTimeout(marker);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedClearTimeout(marker);
    } catch (e) {
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
        } catch (e) {
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
        }
    }
}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while (len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;
process.prependListener = noop;
process.prependOnceListener = noop;

process.listeners = function (name) {
    return [];
};

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () {
    return '/';
};
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function () {
    return 0;
};

},{}],28:[function(require,module,exports){
'use strict';

// // import Promise from 'promise-polyfill';

// // To add to window
// if (!window.Promise) {
//   window.Promise = Promise;
// }
var axios = require('axios');
// import scrollama from 'scrollama';

window.PATH_APP = '';

var ancho = $(window).width();
var alto = $(window).height();
var anchoSel = 85 * ancho / 100;
var altoSel = 75 * alto / 100;
var anchoSelMob = 92 * ancho / 100;
var altoSelMob = 80 * alto / 100;
var urlData = '../data/infos.json';
var urlDataIllus = '../data/illus.json';
var infosLand = document.getElementById("infosLand");
var illusLand = document.getElementById("illusLand");
var bigSel = document.getElementById("bigSel");
var bigBig = document.getElementById("bigBig");
var verBig = document.getElementById("verBig");
var verInfo = document.getElementById("verInfo");
var verIllus = document.getElementById("verIllus");
var infosFiltered = [];
var infosTotal = [];
var illusTotal = [];
var infosTotalFiltrada = [];
var illusFiltered = [];
var filtra = false;

var app = {
    init: function init() {
        var requestDatos = axios.get(urlData);
        var requestDatosIllus = axios.get(urlDataIllus);

        axios.all([requestDatos, requestDatosIllus]).then(axios.spread(function () {
            for (var _len = arguments.length, responses = Array(_len), _key = 0; _key < _len; _key++) {
                responses[_key] = arguments[_key];
            }

            var datos = responses[0].data;
            var datosIllus = responses[1].data;
            app.getData(datos, datosIllus);
            app.armado(datos, "#infosLand");
            app.armado(datosIllus, "#illusLand");
            app.clickBoton();
            app.clickHambur();
            app.clickSelect(datos);
            app.classLetters(hiTit);
            app.classLetters(infosTit);
            app.classLetters(illusTit);
            app.classLetters(nombre);
            app.classLetters(nombreMob);
            app.classLetters(infosinBarreras);
            app.classLetters(infothames);
            app.classLetters(infoetiquetado);
            app.classLetters(infolistasPaso);
            app.classLetters(infocopaAmerica21);
            app.classLetters(infovacunas);
            app.classLetters(infomonitor);
            app.classLetters(infoadopciones);
            app.classLetters(infomarsPerseveranceB);
            app.classLetters(infovirusColectivo);
            app.classLetters(infovirusFiestas);
            app.classLetters(infomaradonaFrases);
            app.classLetters(infomessi700);
            app.classLetters(infotablaCoronavirus);
            app.classLetters(infofrasesSesiones1);
            app.classLetters(infoespecial2020);
            app.classLetters(infocompras100pe);
            app.classLetters(infofixtureAmer20);
            app.classLetters(infoescuelas2019);
            app.classLetters(infodebatePresi2019);
            app.classLetters(infopaso2019);
            app.classLetters(infoluna50B);
            app.classLetters(infocuras);
            app.classLetters(infosubAra);
            app.classLetters(infotronos2019);
            app.classLetters(infojohnoliver);
            app.classLetters(infog20favor);
            app.classLetters(infoarmaBoca);
            app.classLetters(infofinalLibertadores);
            app.classLetters(infojjooJuventud);
            app.classLetters(infomejorGol);
            app.classLetters(infopuntajeLN);
            app.classLetters(infoArgCroacia);
            app.classLetters(infoestadios);
            app.classLetters(infoelegiArg);
            app.classLetters(infolugares);
            app.classLetters(infodesaparecidos);
            app.classLetters(infosubAraResumen);
            app.classLetters(infogameTronos);
            app.classLetters(infocalcEliminatorias);
            app.classLetters(inforugbyB);
            app.classLetters(infomexico86);
            app.classLetters(infostarwars);
            app.armaLineas();
            app.tocaBody("#bigSel", "active");
            app.tocaBody("#bigBig", "active");
            app.tocaBody("#bigMenu", "active");
            app.tocaBody("#opts", "open");
        })).catch(function (errors) {
            // react on errors.
            console.log(errors);
        });

        setInterval(function () {
            // pymChild.sendHeight();
        }, 3000);
    }, // FIN INIT

    getData: function getData(datos, datosIllus) {
        infosTotal = [];
        illusTotal = [];
        var dataCuad = void 0;
        var datoInfCero = datos[0].type;
        var datoIllCero = datosIllus[0].type;

        if (datoInfCero.includes("info")) {
            for (var inf = 0; inf < datos.length; inf++) {
                var tipo = datos[inf].type;
                if (tipo == "info") {
                    dataCuad = {
                        id: "info" + inf,
                        type: datos[inf].type,
                        titulo: datos[inf].titulo,
                        bajada: datos[inf].bajada,
                        seccion: datos[inf].seccion,
                        link: datos[inf].link,
                        video: datos[inf].video,
                        imagen: datos[inf].imagen,
                        skills: datos[inf].skills,
                        anio: datos[inf].anio
                    };
                    infosTotal.push(dataCuad);
                }
            }
        } else {
            for (var ill = 0; ill < datosIllus.length; ill++) {
                var _tipo = datosIllus[ill].type;
                if (_tipo == "illus") {
                    dataCuad = {
                        id: "illus" + ill,
                        type: datosIllus[ill].type,
                        titulo: datosIllus[ill].titulo,
                        bajada: datosIllus[ill].bajada,
                        seccion: datosIllus[ill].seccion,
                        link: datosIllus[ill].link,
                        video: datosIllus[ill].video,
                        imagen: datosIllus[ill].imagen,
                        skills: datosIllus[ill].skills,
                        anio: datosIllus[ill].anio
                    };
                    illusTotal.push(dataCuad);
                }
            }
        }
    },

    classLetters: function classLetters(idClass) {
        var tempLetra = '';
        var ya = [];
        var letraClase = '';
        var idCla = $(idClass).attr("id");
        var cla = $(idClass).attr("class");
        var sinC = cla.replace(/[áéíóúüñcid\s\(\)\(.)\(+)\(*)\(,)/]/g, '');

        if (idCla == "nombreMob" && ancho < 640) {
            var idNR = $("#nombreMob");
            cla = "nr";
            $(idClass).removeClass("nicolas-rivera");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
            if ($(idClass).hasClass("active")) {
                $(idCla).innerHTML("");
                tempLetra = '<img alt="n" class="n" src="https://www.nicolasrivera.com.ar/img/alf/nB.svg"/><img alt="r" class="r" src="https://www.nicolasrivera.com.ar/img/alf/rB.svg"/>';
            }
        } else if (idCla == "infosTit" && ancho < 640) {
            cla = "works";
            $(idClass).removeClass("infographics");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
        } else if (idCla == "illusTit" && ancho < 640) {
            cla = "illus";
            $(idClass).removeClass("illustrations");
            $(idClass).addClass(cla);
            letraClase = cla.split('');
        } else if (idCla == "infosinBarreras" || idCla == "infothames" || idCla == "infoetiquetado" || idCla == "infolistasPaso" || idCla == "infocopaAmerica21" || idCla == "infovacunas" || idCla == "infomonitor" || idCla == "infoadopciones" || idCla == "infomarsPerseveranceB" || idCla == "infovirusColectivo" || idCla == "infovirusFiestas" || idCla == "infomaradonaFrases" || idCla == "infomessi700" || idCla == "infotablaCoronavirus" || idCla == "infofrasesSesiones1" || idCla == "infoespecial2020" || idCla == "infocompras100pe" || idCla == "infofixtureAmer20" || idCla == "infoescuelas2019" || idCla == "infodebatePresi2019" || idCla == "infopaso2019" || idCla == "infoluna50B" || idCla == "infocuras" || idCla == "infosubAra" || idCla == "infotronos2019" || idCla == "infojohnoliver" || idCla == "infog20favor" || idCla == "infoarmaBoca" || idCla == "infofinalLibertadores" || idCla == "infojjooJuventud" || idCla == "infomejorGol" || idCla == "infopuntajeLN" || idCla == "infoArgCroacia" || idCla == "infoestadios" || idCla == "infoelegiArg" || idCla == "infolugares" || idCla == "infodesaparecidos" || idCla == "infosubAraResumen" || idCla == "infogameTronos" || idCla == "infocalcEliminatorias" || idCla == "inforugbyB" || idCla == "infomexico86" || idCla == "infostarwars") {
            cla = sinC;
            letraClase = cla.split('');
        } else {
            cla = $(idClass).attr("class");
            letraClase = cla.split('');
        }

        $.each(letraClase, function (key, value) {
            var letra = letraClase[key];
            if ($.inArray(value, ya) === -1) {
                ya.push(value);
                if (letra == "-") {
                    tempLetra = '<img alt="space" class="espacio" src="https://www.nicolasrivera.com.ar/img/alf/espacio.svg"/>';
                } else {
                    tempLetra = '<img alt="' + letra + '" class="' + letra + '" src="https://www.nicolasrivera.com.ar/img/alf/' + letra + '.svg"/>';
                }
                $("#" + idCla).append(tempLetra);
            } else {
                tempLetra = '<img alt="' + letra + '" class="' + letra + '2" src="https://www.nicolasrivera.com.ar/img/alf/' + letra + '.svg"/>';
                $("#" + idCla).append(tempLetra);
            }
        });
    },

    clickSelect: function clickSelect(datos) {
        var ulOpts = $("#opts");
        var ulOptsMob = $("#optsMob");
        var optChoose = $("#opts .choose");
        var optChooseMob = $("#optsMob .choose");
        var opt = $("#opts .opt");
        var filterValue = void 0;
        var $gridInfos = $('#infosLand');

        if (ancho > 640) {
            $gridInfos = $('#infosLand').isotope({
                itemSelector: '.info',
                layoutMode: 'masonry',
                percentPosition: true,
                masonry: {
                    columnWidth: '.info'
                },
                hiddenStyle: {
                    opacity: 0
                },
                visibleStyle: {
                    opacity: 1
                }
            });
        } else {
            $gridInfos = $('#infosLand').isotope({
                itemSelector: '.info',
                layoutMode: 'fitRows',
                percentPosition: true,
                masonry: {
                    columnWidth: '.info'
                },
                hiddenStyle: {
                    opacity: 0
                },
                visibleStyle: {
                    opacity: 1
                }
            });
        }
        optChoose.on("click", function () {
            ulOpts.toggleClass("open");
            $(bigSel).addClass("active");
        });
        optChooseMob.on("click", function () {
            ulOptsMob.toggleClass("open");
            $(bigSel).addClass("active");
        });
        ulOpts.on('click', '.opt', function () {
            /// / Agrega o saca la clase active de los botones
            // ulOpts.css("height", "294px");
            ulOpts.toggleClass("open");
            $(bigSel).toggleClass("active");
            // ulOpts.css("padding-top", "45px");
            optChoose.css("opacity", 0);
            var botones = document.querySelectorAll('.opt');
            botones.forEach(function (item) {
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            var sortByValue = $(this).attr('data-sort-by'); //* ** Este valor tiene que coincidir con el setup del getSortData del isotope
            $gridInfos.isotope({ filter: sortByValue });
            var elems = $gridInfos.isotope('getFilteredItemElements');
            infosFiltered = [];
            var check = void 0;

            var _loop = function _loop(f) {
                var idEl = $(elems[f]).attr("id");
                var classEl = $(elems[f]).attr("class");
                var el = $(elems[f]).attr("id").replace("info", "");
                var infoFiltrada = { id: idEl, idNum: el };
                // infosFiltered.push(infoFiltrada);
                check = infosTotal.find(function (x) {
                    return x.id === idEl;
                });
                infosFiltered.push(check);
                // if(check){
                //     console.log("ES IGUAL", infosFiltered[f]["id"], check, check.bajada)
                // } else {
                //     console.log("NOOO ES IGUAL", infosFiltered[f]["id"], infosTotal[f])
                // }
                // if (classEl.includes("info")){
                //     console.log("ALALLALALA")
                //     app.abre(".info", infosFiltered);
                // } else {
                //     app.abre(".illus", datos);
                // }
            };

            for (var f = 0; f < elems.length; f++) {
                _loop(f);
            }
            // $(bigSel).removeClass("active");

            // filtra = true;
            // app.abre(".info", infosFiltered);
            // app.bigActive(infosFiltered, check.id);
        });
        ulOptsMob.on('click', '.opt', function () {
            /// / Agrega o saca la clase active de los botones
            // ulOpts.css("height", "294px");
            ulOptsMob.toggleClass("open");
            $(bigSel).toggleClass("active");
            // ulOptsMob.css("margin-top", "45px");
            optChoose.css("opacity", 0);
            var botones = document.querySelectorAll('.opt');
            botones.forEach(function (item) {
                item.classList.remove('selected');
            });
            this.classList.add('selected');
            var sortByValue = $(this).attr('data-sort-by'); //* ** Este valor tiene que coincidir con el setup del getSortData del isotope
            $gridInfos.isotope({ filter: sortByValue });
            filtra = true;
        });
    },
    armaLineas: function armaLineas() {
        var ancho = $(window).width();
        var alto = $(window).height();
        var superficie = ancho / 20 * (alto / 20);
        var fondo = document.getElementById("hiLand");
        var linea = '<div class=\'line\' style=\'background-color:' + randomOpacity() + '; height:100vh;\'></div>';

        function randomOpacity() {
            var color = void 0;

            for (var c = 0; c <= 255; c++) {
                var col1 = Math.floor(Math.random() * 256);
                var col2 = Math.floor(Math.random() * 256);
                var col3 = Math.floor(Math.random() * 256);
                var opa = Math.random().toFixed(1);
                color = 'rgba(' + col1 + ',' + col2 + ',' + col3 + ',' + opa + ')';
            }
            setInterval(3000);

            return color;
        }
        var num = 0;

        function setProperty() {
            var circNum = Math.floor(Math.random() * 100);
            var lineNum = Math.floor(Math.random() * 200);
            var divLineCirc = lineNum / circNum;
            var topTria = Math.floor(Math.random() * 150);
            var topNumber = Math.floor(Math.random() * 200);
            var widthLine = Math.floor(Math.random() * 10);
            var widthtria = Math.floor(Math.random() * 80);
            var widthCirc = Math.floor(Math.random() * 50);
            var topCirc = Math.floor(Math.random() * 100);
            num++;

            var time = Math.random();
            var timeRandom = Math.random();
            var circ = document.createElement('div');
            circ.id = 'circ' + num;
            circ.setAttribute("class", "circ");
            circ.style.width = widthCirc + "px";
            circ.style.height = widthCirc + "px";
            circ.style.backgroundColor = randomOpacity();
            circ.style.left = time * topNumber + "%";
            circ.style.setProperty('--top', topCirc + "%");

            var line = document.createElement('div');
            line.id = 'line' + num;
            line.setAttribute("class", "line");
            line.style.width = widthLine + "px";
            line.style.height = "100vh";
            line.style.backgroundColor = randomOpacity();
            line.style.left = time * topNumber + "%";

            if (Math.round(divLineCirc) == 2) {
                var tria = document.createElement('div');
                tria.id = 'tria' + num;
                tria.setAttribute("class", "tria");
                tria.style.width = widthtria + "px";
                tria.style.height = widthtria + "px";
                tria.style.left = time * topNumber + "%";
                tria.style.setProperty('--top', topTria + "%");
                tria.style.boxShadow = "15px 15px 0 0 " + randomOpacity();
                $("#hiLand").append(tria);
            } else {
                $("#hiLand").append(circ);
            }

            $("#hiLand").append(line);
        }

        var appendDuration = Math.random();
        setProperty(appendDuration);
        setInterval(setProperty, 1000);
    },

    armado: function armado(datos, cont) {
        var contenedor = $(cont).attr("id");

        var info = '';
        for (var i = 0; i < datos.length; i++) {
            var titulo = datos[i].titulo;
            var anio = datos[i].anio;
            var bajada = datos[i].bajada;
            var link = datos[i].link;
            var seccion = datos[i].seccion;
            var seccionOk = seccion.replace(",", "");
            var skills = datos[i].skills;
            var video = datos[i].video;
            var imagen = datos[i].imagen;
            var imagenExt = imagen.split(".");
            var imageSinExt = imagenExt[0].replace(/[áéíóúüñ-\s\(\)\(&)\(-)\(_)\(*)\(,)/]/g, '');

            if (contenedor == "infosLand") {
                info += '<a title="link to a work" class="linkBig" href=' + link + ' target="_blank">\n                <div id="info' + i + '" class="info ' + seccionOk + '">';
                if (video == "si") {
                    info += '<video class="imgInfo vid" playsinline autoplay muted loop>\n                        <source src="https://www.nicolasrivera.com.ar/img/infos/' + imagen + '.mp4" type="video/mp4">\n                        <source src="https://www.nicolasrivera.com.ar/img/infos/' + imagen + '.ogg" type="video/ogg">\n                        Your browser does not support the video tag.\n                    </video>';
                } else {
                    info += '<div class="imgInfo" style="background-image:url(https://www.nicolasrivera.com.ar/img/infos/' + imagen + '.jpg)"></div>';
                }
                info += '<div class="infoInfo">\n                <div id="info' + imageSinExt + '" class="c' + anio + '"></div>\n                <h2 class="titu">' + titulo + '</h2>\n                <p class="descInfo">' + bajada + '</p>\n                <p class="link">SEE MORE</p>\n                <p class="anio">' + skills + '</p>\n                </div>\n                </div></a>';
            } else {
                if (link) {
                    info += '<a title="link to an illustration" class="linkBig" href=' + link + ' target="_blank">\n                        <div id="illus' + i + '" class="illus">';
                    if (video == "si") {
                        info += '<video class="imgIllus" playsinline autoplay muted loop>\n                                <source src="https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.mp4" type="video/mp4">\n                                <source src="https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.ogg" type="video/ogg">\n                                Your browser does not support the video tag.\n                            </video>';
                    } else {
                        info += '<div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.jpg)"></div>';
                    }
                    info += '<div class="infoIllus">\n                                <h2 class="titu">' + titulo + '</h2>\n                                <h2 class="descIllus">' + bajada + '</h2>\n                                <p class="link">SEE MORE</p>\n                                <p class="anio">' + skills + '</p>\n                            </div>\n                        </div></a>';
                } else {
                    info += '<div id="illus' + i + '" class="illus">';
                    if (video == "si") {
                        info += '<video class="imgIllus" playsinline autoplay muted loop>\n                            <source src="https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.mp4" type="video/mp4">\n                            <source src="https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.ogg" type="video/ogg">\n                            Your browser does not support the video tag.\n                        </video>';
                    } else {
                        info += '<div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/' + imagen + '.jpg)"></div>';
                    }
                    info += '<div class="infoIllus">\n                            <h2 class="titu">' + titulo + '</h2>\n                            <h2 class="descIllus">' + bajada + '</h2>\n                            <p class="anio">' + skills + '</p>\n                        </div>\n                    </div>';
                }
            }
        }
        $(cont).append(info);
    },
    tocaBody: function tocaBody(idSelec, classSel) {

        if (idSelec === "#bigBig") {
            $(idSelec).click(function () {
                $(idSelec).removeClass(classSel);
                $("#verBig").removeClass(classSel);
                $("#bigSel").removeClass(classSel);
            });
        }
        if (idSelec === "#bigMenu") {
            $(idSelec).click(function () {
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $('#nav-icon3').toggleClass('open');
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
                $(idSelec).removeClass(classSel);
            });
        }
        if (idSelec === "#bigSel") {
            $(idSelec).click(function () {
                $("#opts").removeClass("open");
                $("#optsMob").removeClass("open");
                $("#bigBig").removeClass(classSel);
                $("#verBig").removeClass(classSel);
                $(idSelec).removeClass(classSel);
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            });
        }
    },

    abre: function abre(cuad, datos) {
        var idCuad = '';
        var idNum = void 0;
        var imageOpen = void 0;
        $(cuad).click(function () {
            idCuad = $(this).attr("id");
            $("#menu").removeClass("active");
            $("#opts").removeClass("open");
            $("#optsMob").removeClass("open");
            $("#nombreMob").removeClass("active");
            $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
            $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            $(bigSel).removeClass("active");
            $(bigBig).removeClass("active");
            $(verBig).removeClass("active");
            idNum = idCuad.replace("illus", "");
            app.bigActive(datos, idCuad);
            $("#illusSel" + idNum).addClass("active");
            $(bigBig).addClass("active");
            $(verBig).addClass("active");
            imageOpen = $(".container #verBig.active #illusSel" + idNum + ".active .imgIllus");

            if (ancho > 500) {
                $(verBig).css("width", anchoSel + "px");
                $(verBig).css("height", altoSel + "px");
                $(verBig).css("left", (ancho - anchoSel) / 2 + "px");
                $(verBig).css("top", (alto - altoSel) / 2 + "px");
                $(imageOpen).css("height", altoSel + "px");
            }
        });
    },
    bigActive: function bigActive(datos, idCuad) {
        var tempSel = '';
        var idNum = 0;
        var idNumFil = 0;
        var titulo = void 0;
        var bajada = void 0;
        var link = void 0;
        var skills = void 0;
        var imagen = void 0;
        if (idCuad.includes("info")) {
            idNum = idCuad.replace("info", "");
        } else {
            idNum = idCuad.replace("illus", "");
        }
        $(".contBig").removeClass("active");

        titulo = datos[idNum].titulo;
        bajada = datos[idNum].bajada;
        link = datos[idNum].link;
        skills = datos[idNum].skills;
        imagen = datos[idNum].imagen;
        tempSel += '<div id="illusSel' + idNum + '" class="contBig">\n                    <div class="imgIllus" style="background-image:url(https://www.nicolasrivera.com.ar/img/illus/' + imagen + ')"></div>\n                    <div class="datosIllus"><div id="bots"><div class="cerrar" id="cierra"></div><div class="bot" id="menos"></div><div class="bot" id="mas"></div></div>\n                        <h3 class="tituIllus">' + titulo + '</h3>\n                        <p class="descInfo">' + bajada + '</p>\n                        <p class="skills">' + skills + '</p>\n                    </div>\n                </div>';
        $(verBig).html(tempSel);

        if (idNum == 0) {
            $("#menos").addClass("no");
        }
        if (idNum == datos.length - 1) {
            $("#mas").addClass("no");
        }
        app.masMenos(datos, idNum);
        app.cierra(idNum);
    },
    masMenos: function masMenos(datos, num) {
        var botMas = $("#mas");
        var botMenos = $("#menos");

        $(botMas).click(function () {
            var padre = this.parentElement.parentElement.parentElement.id;

            $("#illusSel" + num).removeClass("active");
            $(".contBig").removeClass("active");
            num++;
            if (num < datos.length) {
                app.bigActive(datos, "illus" + num);
                $("#illusSel" + num).addClass("active");
            }
            $(botMas).css("opacity", 0);
            var imgIllus = $(".container #verBig.active #illusSel" + num + ".active .imgIllus");
            $(imgIllus).css("height", altoSel + "px");
        });
        $(botMenos).click(function () {
            var padre = this.parentElement.parentElement.parentElement.id;
            $("#illusSel" + num).removeClass("active");
            $(".contBig").removeClass("active");
            num--;

            app.bigActive(datos, "illus" + num);
            $("#illusSel" + num).addClass("active");
            if (num == 0) {
                $(botMenos).addClass("no");
            }

            var imgIllus = $(".container #verBig.active #illusSel" + num + ".active .imgIllus");
            $(imgIllus).css("height", altoSel + "px");
        });
    },
    cierra: function cierra(idVer) {
        var close = document.getElementById("cierra");
        $(close).click(function () {
            $(idVer).removeClass("active");
            $(bigSel).removeClass("active");
            $(bigBig).removeClass("active");
            $(verBig).removeClass("active");
        });
    },

    clickBoton: function clickBoton() {
        var botHi = document.getElementById("hola");
        var botInfos = document.getElementById("infos");
        var botIllus = document.getElementById("illus");
        var botHiMob = document.getElementById("holaMob");
        var botInfosMob = document.getElementById("infosMob");
        var botIllusMob = document.getElementById("illusMob");
        var botTop = document.getElementById("top");
        var landInfos = document.getElementById("infosLand");
        var landHi = document.getElementById("hiLand");
        var selInfos = document.getElementById("sel");
        var selInfosMob = document.getElementById("selMob");
        var landIllus = document.getElementById("illusLand");
        var posInfos = landInfos.getBoundingClientRect();
        var posIllus = landIllus.getBoundingClientRect();
        var porcen = ancho - 100;

        botTop.onclick = function (event) {
            // Click en boton del footer
            var href = $(this).attr('href');
            var hash = href.substr(href.indexOf('#'));

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'linear', function () {});
            }
            setTimeout(function () {}, 1500);
        }; // End click
        botIllus.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "none");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'linear', function () {});
            }
            setTimeout(function () {}, 1500);
        }; // End click
        botIllusMob.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#optsMob").removeClass("open");
            $(selInfosMob).css("display", "none");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'linear', function () {});
            }
            setTimeout(function () {}, 1500);
            setTimeout(function () {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        }; // End click

        botHi.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "none");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'swing', function () {});
            }
            setTimeout(function () {}, 1500);
        }; // End click
        botHiMob.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#optsMob").removeClass("open");
            $(selInfosMob).css("display", "none");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'swing', function () {});
            }
            setTimeout(function () {}, 1500);
            setTimeout(function () {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        }; // End click
        botInfos.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(verInfo).css("left", (ancho - anchoSel) / 2 + "px");
            $(verInfo).css("top", (alto - altoSel) / 2 + "px");
            $(bigSel).removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $("#opts").removeClass("open");
            $(selInfos).css("display", "inline");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'swing', function () {});
            }
            setTimeout(function () {}, 1500);
        }; // End click
        botInfosMob.onclick = function (event) {
            // Click en botones del nav
            var href = $(this).children().attr('href');
            var hash = href.substr(href.indexOf('#'));
            $(bigSel).removeClass("active");
            $("#bigMenu").removeClass("active");
            $("#bigBig").removeClass("active");
            $("#verBig").removeClass("active");
            $("#verInfo").removeClass("active");
            $("#verIllus").removeClass("active");
            $(selInfosMob).css("display", "inline");

            if (this.hash !== '') {
                event.preventDefault();
                var body = $("html, body");
                var offs = -150;
                if (ancho < 640) {
                    offs = -100;
                }
                body.stop().animate({ scrollTop: $(hash).offset().top + offs }, 1000, 'swing', function () {});
            }
            setTimeout(function () {}, 1500);
            setTimeout(function () {
                $('#nav-icon3').removeClass('open');
                $("#menu").removeClass("active");
                $("#nombreMob").removeClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");
            }, 500);
        }; // End click
    },

    clickHambur: function clickHambur() {
        var tempLetra = '';

        $(document).ready(function () {
            $('#nav-icon3').click(function () {
                $(this).toggleClass('open');
                $("#menu").toggleClass("active");
                $("#nombreMob").toggleClass("active");
                $("#bigMenu").toggleClass("active");
                $("#nombreMob.nr .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/n.svg");
                $("#nombreMob.nr .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/r.svg");

                if ($("#menu").hasClass('active')) {
                    $("#nombreMob.nr.active .n").attr("src", "https://www.nicolasrivera.com.ar/img/alf/nB.svg");
                    $("#nombreMob.nr.active .r").attr("src", "https://www.nicolasrivera.com.ar/img/alf/rB.svg");
                }
            });
        });
    }
};

app.init();

window.onload = function () {
    /** resize app */
    var doit = void 0;
    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
            console.log('resize app');
        }, 200);
    };
};

},{"axios":1}]},{},[28])

